import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { WorkflowRoutingComponent } from './workflow-routing.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [WorkflowRoutingComponent],
  exports: [WorkflowRoutingComponent],
})
export class WorkFlowRoutingModule {}
