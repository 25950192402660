import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConstants } from '../common/app.constants';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.error instanceof ErrorEvent) {
          console.error(
            `This is client side error, Error: ${err.error.message}`
          );
        } else {
          console.error(
            `This is server side error, ErrorCode: ${err.status} Message: ${err.message}`
          );
        }

        // Couldnt find api route
        let error = AppConstants.APIGenericError;

        // Auth error
        if (err.status === 401 || err.status === 404) {
          error = `${AppConstants.APIRouteError} ${err.url}`;
        }

        if (err.status === 500) {
          error = AppConstants.APIInternalServerError;
        }

        if (err.error != undefined && err.error.message !== undefined) {
          error = err.error.message;
        }

        if (
          err.error !== null &&
          err.error.errorMessages !== undefined &&
          err.error.errorMessages.length > 0
        ) {
          error = err.error.errorMessages;
        }

        if (err.error !== null && err.error.authErrors !== undefined) {
          error = err.error.authErrors[0];
        }

        throw new HttpErrorResponse({
          error: error,
          headers: err.headers,
          status: err.status,
          statusText: err.statusText,
          url: err.url,
        });
      })
    );
  }
}
