import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AddTrialComponent } from '../trial/add-trial/add-trial.component';
import { EditTrialComponent } from './edit-trial/edit-trial.component';
import { AddTrialUserListComponent } from './edit-trial/add-trialuser-list/add-trialuser-list.component';
import { AddTrialUsersComponent } from './edit-trial/add-trialuser-list/add-trialusers/add-trialusers.component';
import { EditTrialUsersComponent } from './edit-trial/edit-trialusers/edit-trialusers.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    AddTrialComponent,
    EditTrialComponent,
    AddTrialUserListComponent,
    AddTrialUsersComponent,
    EditTrialUsersComponent,
  ],

  exports: [
    AddTrialComponent,
    EditTrialComponent,
    AddTrialUserListComponent,
    AddTrialUsersComponent,
    EditTrialUsersComponent,
  ],
})
export class TrialModule {}
