import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppsettingsService {
  public app: any;
  loginUrl: string;
  baseUrl: string;
  emptyMessageText: string;

  constructor() {
    this.app = {
      name: 'Lynx',
      description: 'LIDO - CTOS',
      year: new Date().getFullYear(),
    };
    this.baseUrl = environment.apiUrl + '/';
    this.loginUrl = '/auth/login';
    this.emptyMessageText = 'No Record Found';
  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
}
