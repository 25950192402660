import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { TermsOfUseComponent } from './terms-of-use.component';
import { TermsOfUseTextComponent } from './terms-text/terms-of-use-text.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    TermsOfUseComponent,
    TermsOfUseTextComponent
  ],
  exports: [
    TermsOfUseComponent,
    TermsOfUseTextComponent
  ],
})
export class TermsOfUseModule {}
