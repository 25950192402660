import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router) {}
  canActivate(): boolean {
    const isLoggedIn = sessionStorage.getItem('currentUser');
    if (!isLoggedIn) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    return true;
  }
}
