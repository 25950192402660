import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-p-breadcrumbs',
  templateUrl: './p-breadcrumbs.component.html',
  styleUrls: ['./p-breadcrumbs.component.scss'],
})
export class PrimeNgBreadcrumbComponent {
  @Input() public items: MenuItem[] = [];
}
