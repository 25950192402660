import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncrDecrService {
  encryptSecretKey = 'd57e8fad-059a-42af-a86c-ce9bf8e3a3a7';
  isEncryptRequired = true;

  encryptData(data) {
    try {
      if (this.isEncryptRequired === true) {
        return Array.isArray(data)
          ? CryptoJS.AES.encrypt(
              JSON.stringify(this.convertArrayToString(data)),
              this.encryptSecretKey
            ).toString()
          : CryptoJS.AES.encrypt(
              JSON.stringify(data),
              this.encryptSecretKey
            ).toString();
      } else {
        return JSON.stringify(data);
      }
    } catch (e) {
      console.error('in catch for encrypt data!');
      throw e;
    }
  }

  convertArrayToString(data: any[]): string {
    const retvalue = data.map((x) => {
      return x.toString();
    });
    return retvalue.join(',');
  }

  decryptData(data) {
    if (data === undefined || data === null) {
      return data;
    }

    if (this.isEncryptRequired === true) {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } else {
        console.error('bytestostring didnt pass');
        return '';
      }
    } else {
      JSON.parse(data);
    }
  }
}
