import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

/*
To add size class -
<i class="fa fa-camera-retro fa-lg"></i> fa-lg
<i class="fa fa-camera-retro fa-2x"></i> fa-2x
<i class="fa fa-camera-retro fa-3x"></i> fa-3x
<i class="fa fa-camera-retro fa-4x"></i> fa-4x
<i class="fa fa-camera-retro fa-5x"></i> fa-5x
*/

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fa',
  templateUrl: './fa.component.html',
  styleUrls: ['./fa.component.scss'],
})
export class FontAwesomeComponent implements AfterViewInit {
  @Input() name = '';
  @Input() size = '';
  @Input() color = '';
  @Input() background = '';
  @ViewChild('icon') icon: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    if (this.size !== '') {
      this.icon.nativeElement.classList.add(this.size);
    }
    if (this.color !== '') {
      this.icon.nativeElement.classList.add('clr-' + this.color);
    }
    if (this.background !== '') {
      this.icon.nativeElement.classList.add('bg-' + this.background);
    }
  }
}
