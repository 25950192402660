import { Injectable } from '@angular/core';

import { getCurrentUserRole, getCurrentUserType } from 'src/app/common/utils';

@Injectable({
  providedIn: 'root',
})
export class AppAccessService {
  maxUserTypes = 3;
  accessTable = [
    {
      appFunction: 'Site Home',
      userType: ['Site Administrator', 'Site User', 'External User'],
    },
    {
      appFunction: 'Home',
      userType: ['Site Administrator', 'Site User', 'External User'],
    },
    {
      appFunction: 'Trial Home',
      userType: ['Site Administrator', 'Site User', 'External User'],
      userRole: [
        'Principal Investigator',
        'Sub Investigator',
        'Research Coordinator',
        'Data Manager',
        'External User',
      ],
    },
    {
      appFunction: 'My Profile',
      userType: ['Site Administrator', 'Site User', 'External User'],
    },
    {
      appFunction: 'My Site Settings',
      userType: ['Site Administrator', 'Site User'],
    },
    {
      appFunction: 'Help',
      userType: ['Site Administrator', 'Site User', 'External User'],
    },
    {
      appFunction: 'Add - Edit Site',
      userType: ['Site Administrator'],
    },
    {
      appFunction: 'Site User Management',
      userType: ['Site Administrator'],
    },
    {
      appFunction: 'Site Docs',
      userType: ['Site Administrator', 'Site User'],
    },
    {
      appFunction: 'Trial User Management',
      userType: ['Site Administrator'],
    },
    {
      appFunction: 'Add - Edit Trial',
      userType: ['Site Administrator'],
    },
    {
      appFunction: 'Archived Trial List',
      userType: ['Site Administrator', 'Site User'],
    },
    {
      appFunction: 'Vendors',
      userType: ['Site Administrator', 'Site User'],
    },
    {
      appFunction: 'Folder List',
      userType: ['Site Administrator', 'Site User', 'External User'],
      userRole: [
        'Principal Investigator',
        'Sub Investigator',
        'Research Coordinator',
        'Data Manager',
        'External User',
      ],
    },
    {
      appFunction: 'Add/Edit Docs',
      userType: ['Site Administrator', 'Site User', 'External User'],
    },
    {
      appFunction: 'Add/Edit Subjects',
      userType: ['Site Administrator', 'Site User'],
    },
    {
      appFunction: 'Show Archived Docs',
      userType: ['Site Administrator', 'Site User'],
    },
    {
      appFunction: 'Doc Flow Log',
      userType: ['Site Administrator', 'Site User'],
    },
  ];

  hasAccess(appFunction: string) {
    const userType = getCurrentUserType();
    const userRole = getCurrentUserRole();

    if (userType === undefined) {
      console.info('undefined user type!');
    }

    const accessItem = this.accessTable.find(
      (x) => x.appFunction.toLowerCase() === appFunction.toLowerCase()
    );
    if (accessItem === undefined) {
      console.warn('Couldnt find accessItem ', appFunction);
      return true;
    }

    // See if the user has this type
    if (userType !== undefined) {
      const typeIdx = accessItem.userType.findIndex((x) => x === userType);
      if (typeIdx === -1) {
        return false;
      }
    } else {
      // Usertype is undefined but all usertypes can do this so let em by
      if (accessItem.userType.length === this.maxUserTypes) {
        return true;
      } else {
        return false;
      }
    }

    if (accessItem.userRole === undefined) {
      return true;
    }
    const roleIdx = accessItem.userRole.findIndex((x) => x === userRole);
    if (roleIdx === -1) {
      return false;
    }
    return true;
  }
}
