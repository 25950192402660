import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { DataViewModule } from 'primeng/dataview';

import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { AccessDirective } from '../core/directives/access.directive';
import { DocTypeIconComponent } from './doc-type-icon/doc-type-icon.component';
import { ByteFormatPipe } from './pipes/byte-format.pipe';
import { DocumentPathPipe } from './pipes/doc-path.pipe';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeModule } from 'primeng/tree';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ListboxModule } from 'primeng/listbox';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccordionModule } from 'primeng/accordion';
import { StepsModule } from 'primeng/steps';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeSelectModule } from 'primeng/treeselect';
import { FontAwesomeComponent } from './fontawesome/fa.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PrimeNgBreadcrumbComponent } from './p-breadcrumb/p-breadcrumbs.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { TrialRoleInitialPipe } from './pipes/trialrole-string.pipe';
import { ImageCacheBustPipe } from './pipes/logocache.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataViewModule,
    ButtonModule,
    TooltipModule,
    OverlayPanelModule,
    DialogModule,
    DragDropModule,
    InputTextModule,
    PanelModule,
    DropdownModule,
    CalendarModule,
    SidebarModule,
    RadioButtonModule,
    CheckboxModule,
    TreeModule,
    TreeSelectModule,
    TableModule,
    InputSwitchModule,
    ToggleButtonModule,
    FileUploadModule,
    MessageModule,
    MessagesModule,
    ListboxModule,
    InputMaskModule,
    ToastModule,
    PasswordModule,
    AutoCompleteModule,
    SplitButtonModule,
    MenuModule,
    BreadcrumbModule,
    ContextMenuModule,
    ConfirmDialogModule,
    FieldsetModule,
    DynamicDialogModule,
    TabViewModule,
    ScrollPanelModule,
    AccordionModule,
    StepsModule,
    MultiSelectModule,
    NgxDocViewerModule,
  ],
  declarations: [
    AccessDeniedComponent,
    DocumentViewerComponent,
    PrimeNgBreadcrumbComponent,
    PageNotFoundComponent,
    AccessDirective,
    DocTypeIconComponent,
    TruncateStringPipe,
    TrialRoleInitialPipe,
    ByteFormatPipe,
    DocumentPathPipe,
    ImageCacheBustPipe,
    FontAwesomeComponent,
  ],
  exports: [
    AccessDeniedComponent,
    DocumentViewerComponent,
    PrimeNgBreadcrumbComponent,
    PageNotFoundComponent,
    DocTypeIconComponent,
    FontAwesomeComponent,
    TruncateStringPipe,
    TrialRoleInitialPipe,
    ByteFormatPipe,
    DocumentPathPipe,
    ImageCacheBustPipe,
    AccessDirective,
    FormsModule,
    ReactiveFormsModule,
    DataViewModule,
    ButtonModule,
    DialogModule,
    DragDropModule,
    InputTextModule,
    PanelModule,
    TooltipModule,
    OverlayPanelModule,
    DropdownModule,
    CalendarModule,
    SidebarModule,
    TableModule,
    RadioButtonModule,
    CheckboxModule,
    TreeModule,
    TreeSelectModule,
    InputSwitchModule,
    ToggleButtonModule,
    FileUploadModule,
    MessageModule,
    MessagesModule,
    ListboxModule,
    InputMaskModule,
    ToastModule,
    PasswordModule,
    AutoCompleteModule,
    SplitButtonModule,
    ContextMenuModule,
    MenuModule,
    BreadcrumbModule,
    ConfirmDialogModule,
    FieldsetModule,
    DynamicDialogModule,
    TabViewModule,
    CheckboxModule,
    ScrollPanelModule,
    AccordionModule,
    StepsModule,
    MultiSelectModule,
    NgxDocViewerModule,
  ],
  providers: [],
})
export class SharedModule {}
