import { Directive, OnChanges, Input, ElementRef } from '@angular/core';
import { AppAccessService } from './access.service';

@Directive({
  selector: '[appAccess], [appAccessHide]',
})
export class AccessDirective implements OnChanges {
  @Input() appAccess: boolean | string;
  @Input() appAccessHide: boolean;
  @Input() disabled: boolean;
  initted = false;
  initialDisplay: string;
  hasAccess = true;
  hideMode = false;

  // TODO: Accept multiple values on appAccess
  constructor(
    private appAccessService: AppAccessService,
    private el: ElementRef
  ) {}
  ngOnChanges(): void {
    if (this.appAccess === undefined && this.appAccessHide === undefined) {
      return;
    }

    this.hideMode = this.appAccess === undefined ? true : false;
    if (!this.initted) {
      this.init();
    }
    if (this.disabled) {
      // Nothing more to do
      this.disable();
      return;
    }

    if (this.hideMode) {
      if (typeof this.appAccessHide === 'boolean') {
        this.hasAccess = this.appAccessHide;
      } else {
        this.hasAccess = this.appAccessService.hasAccess(this.appAccessHide);
      }
    } else {
      if (typeof this.appAccess === 'boolean') {
        this.hasAccess = this.appAccess;
      } else {
        this.hasAccess = this.appAccessService.hasAccess(this.appAccess);
      }
    }

    if (!this.hasAccess) {
      this.disable();
    } else {
      this.enable();
    }
  }

  init() {
    this.initted = true;
    this.initialDisplay = this.el.nativeElement.style.display;
    this.el.nativeElement.addEventListener('mousedown', (e) => {
      if (!this.hasAccess) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
    this.el.nativeElement.addEventListener('click', (e) => {
      if (!this.hasAccess) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
  }

  // TODO: Class for access denied
  disable() {
    this.el.nativeElement.setAttribute('disabled', true);
    this.el.nativeElement.classList.add('access-denied');

    if (this.appAccessHide) {
      this.el.nativeElement.style.display = 'none';
    }
  }

  enable() {
    this.el.nativeElement.removeAttribute('disabled');
    this.el.nativeElement.classList.remove('access-denied');

    if (this.appAccessHide) {
      this.el.nativeElement.style.display = this.initialDisplay;
    }
  }
}
