import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';

import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { LayoutFooterComponent } from './components/layout-footer/layout-footer.component';
import { ProfileLayoutComponent } from './components/header/profile/profile-layout.component';
import { AlertsComponent } from './components/header/alerts/alerts.component';
import { NotificationsComponent } from './components/header/notifications/notifications.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    LayoutFooterComponent,
    NotificationsComponent,
    AlertsComponent,
    ProfileLayoutComponent,
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    LayoutFooterComponent,
    NotificationsComponent,
    AlertsComponent,
    ProfileLayoutComponent,
  ],
})
export class LayoutModule {}
