export const environment = {
  production: false,
  apiUrl: 'https://lynkdev.azurewebsites.net/services',
  redirectUrl: 'https://devapp.lido.io',
  imageContainer: 'https://lynkdevimages.blob.core.windows.net',
  oktaConfig: {
    issuer: 'https://lido-io.okta.com',
    clientId: '0oadrigst4oULP5hF4x7',
    redirectUri: 'https://devapp.lido.io',
  },
  adobeClientId: 'ats-cec853f6-b4f6-4a3f-ac9e-495bb693c349',
  adobeAuthUrl: 'https://secure.na3.echosign.com/public/oauth/v2',
};
