import { Component, OnInit } from '@angular/core';
import { EncrDecrService } from '../../../EncrDecr/encr-decr.service';

import { NavMenuService } from '../services/nav-menu.service';
import { AppAccessService } from 'src/app/core/directives/access.service';
import { RequestErrors } from 'src/app/common/utils';
import { FactLidoUserEvents } from 'src/app/notifications/model/notifications.model';
import { NotificationService } from 'src/app/notifications/service/notification-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isSiteHome = sessionStorage.getItem('isSiteHome') === 'Yes';
  siteId = Number(sessionStorage.getItem('siteId'));
  showUserManagement = false;
  alerts: FactLidoUserEvents[] = [];
  notifications: FactLidoUserEvents[] = [];
  msgs = [];

  constructor(
    private encrDecrService: EncrDecrService,
    private router: Router,
    private navMenuService: NavMenuService,
    private appAccessService: AppAccessService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    if (this.siteId !== 0) {
      this.getEventList();
    }
    this.navMenuService.siteHomeChanged$.subscribe(() => {
      if (sessionStorage.getItem('isSiteHome') === 'Yes') {
        this.isSiteHome = true;
        this.siteId = 0;
      } else {
        this.isSiteHome = false;
      }
    });
    this.navMenuService.userTypeChanged$.subscribe(() => {
      this.showUserManagement = this.appAccessService.hasAccess(
        'Site User Management'
      );
    });
    this.navMenuService.updateAlerts$.subscribe(() => {
      this.siteId = Number(sessionStorage.getItem('siteId'));
      this.getEventList();
    });
  }

  addRequest(requestType: string) {
    const reqTypeValue = this.encrDecrService.encryptData(requestType);
    this.router.navigate(['/layout/faq/addrequest'], {
      queryParams: {
        reqType: reqTypeValue,
      },
    });
  }

  goToHelpList() {
    this.router.navigate(['/layout/faq/helprequestlist']);
  }

  helpHomePage() {
    this.router.navigate(['/layout/faq/help']);
  }

  getEventList() {
    this.notificationService.getHeaderNotificationList(this.siteId).subscribe(
      (data) => {
        this.alerts = data.filter((x) => x.eventClassification === 'Alert');
        this.notifications = data.filter(
          (x) => x.eventClassification === 'Notification'
        );
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
