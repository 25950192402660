import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MenuItem, Message } from 'primeng/api';
import { UserService } from '../users/service/user.service';
import { User } from '../users/model/user.model';
import { RequestErrors } from '../common/utils';

import { NavMenuService } from '../layout/components/services/nav-menu.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  isTosAccepted = sessionStorage.getItem('isTOSAccepted') === 'true';
  form: UntypedFormGroup;
  user: User;
  msgs: Message[];
  pMenuItems: MenuItem[] = [
    {
      label: 'Terms Of Use',
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private navMenuService: NavMenuService,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (this.isTosAccepted === false) {
      this.initForm();
      this.getUser();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      accept: ['', [Validators.required]],
    });
  }

  getUser() {
    const userId = Number(sessionStorage.getItem('userId'));
    this.userService.getByUserId(userId).subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  onSubmit() {
    // get the user object
    this.user.isTermsOfUseAccepted = true;
    this.userService.editUser(this.user.userId, this.user).subscribe(
      () => {
        sessionStorage.setItem('isTOSAccepted', 'true');
        const numberOfSites = Number(sessionStorage.getItem('numberOfSites'));

        if (numberOfSites > 1) {
          this.navMenuService.setIsSiteHome(true);
          this.router.navigate(['/layout/sitehome']);
        } else {
          this.router.navigate(['/layout/home']);
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
