<div class="b_container">
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
  <p-messages [value]="msgs"></p-messages>
  <app-terms-of-use-text></app-terms-of-use-text>
  <div class="spacer5"></div>
  <div *ngIf="!isTosAccepted">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="checkbox-line">
      <p-checkbox formControlName="accept" binary="true" inputId="access">
      </p-checkbox>
      <label style="margin-left: 10px;" for="accept">I accept the terms of use.</label>
      </div>
      <div class="btnnav">
        <button type="submit" [disabled]="form.controls['accept'].value !== true"
          class="btn btn-primary marright">Submit
        </button>
      </div>
    </form>


  </div>
</div>
