export default {
  oidc: {
    clientId: '0oa2f8jsoEUHQUGNN4x6',
    issuer: 'https://lido-io.okta.com',
    redirectUri: 'https://localhost:4200',
    scope: 'openid profile email',
    testing: {
      disableHttpsCheck: false,
    },

    baseUrl: 'https://lido-io.okta.com/',
    pluginBaseUrl: 'https://lido-io.okta.com',
    pssoUserHomePageUrl: 'https://lido-io.okta.com/app/UserHome',
    homePageUrl: sessionStorage.getItem('commonUrl') + '/#/home',
    ProfilePageUrl: sessionStorage.getItem('commonUrl') + '/#/profile',
  },
  resourceServer: {
    messagesUrl: sessionStorage.getItem('commonUrl') + '/api/messages',
  },
};
