import { environment } from 'src/environments/environment';
export class AppConstants {
  // Urls
  public static readonly OktaPluginUrl =
    'https://lido-io.okta.com/app/UserHome/plugin-info';
  // Error Messages
  public static readonly APIGenericError = 'Bad request';
  public static readonly APIInternalServerError = 'Internal server error';
  public static readonly APIRouteError = 'Bad API route request';
  public static readonly APIAuthError = 'Authentication Error on request';

  // All Dataview ,Data Table --Empty Message
  public static readonly emptyRecordMsg = 'No Record Found';
  public static readonly vendorFlowView = 'grid';
  public static readonly documentFlowView = 'list';
  public static readonly helpView = 'list';

  // home,vendor in home and Vendor Library
  public static readonly gridViewRecsPerPage = 6;
  public static readonly listViewRecsPerPage = 8;
  public static readonly dataViewPagerLinks = 6;

  // Trial desboard,main user list
  public static readonly dataListRecsPerPage = 10;
  public static readonly dataListPagerLinks = 10;

  // Site doc files,operation binder,regular binder and subject document
  public static readonly docListRecsPerPage = 6;
  public static readonly docGridRecsPerPage = 12;
  public static readonly docListPagerLinks = 10;

  // Home page of Site doc ,operation binder,regular binder and subject document
  public static readonly folderListRecsPerPage = 12;
  public static readonly folderListGridPerPage = 6;
  public static readonly folderListPagerLinks = 10;

  // Trial user edit,Proxy Pi and Pi Name,WorkFlow and AdverseEvent user List
  public static readonly trialUserListRecsPerPage = 15;
  public static readonly trialUserListPagerLinks = 15;

  // Cancellable & Actionable Alert
  public static readonly alertsListRecsPerPage = 10;
  public static readonly alertListPagerLinks = 10;

  // --------------------------SSO Value On/Off------------------

  // Upload sizes
  public static readonly maxLogoSize = 102400;
  public static readonly maxDocumentSize = 10485760;

  // SessionStorage keys

  public static readonly AdobeAccessTokenKey = 'adobeAccessToken';
  public static readonly AdobeApiUrlKey = 'adobeApiUrl';
  public static readonly UserIdKey = 'userId';
  public static readonly SubjectNameKey = 'subjectName';

  // Binder folder names
  public static readonly OperationsBinderBlobName = 'operationbinderdocuments';
  public static readonly RegulatoryBinderBlobName = 'regulatorybinderdocuments';
  public static readonly SubjectBinderBlobName = 'subjectdocuments';
  public static readonly SiteDocumentBlobName = 'sitedocuments';

  // Blob storage defaults
  public static readonly defaultSiteLogo = `${environment.imageContainer}/images/sitedefaultimage.png`;
  public static readonly defaultVendorLogo = `${environment.imageContainer}/images/vendordefaultimage.jpg`;
  public static readonly defaultSponsorLogo = `${environment.imageContainer}/images/sponsordefaultimage.jpg`;
  public static readonly loginLogo = `${environment.imageContainer}/images/login-logo.png`;

  // Copy and route urls
  public static readonly OperationBinderDocCopyAndRouteUrl = `${environment.apiUrl}/api/operationbinderdocs/CopyAndRoute`;
  public static readonly RegulatoryBinderDocCopyAndRouteUrl = `${environment.apiUrl}/api/regulatorybinderdocs/CopyAndRoute`;
  public static readonly SiteDocCopyAndRouteUrl = `${environment.apiUrl}/api/siteDocument/CopyAndRoute`;
  public static readonly SubjectDocCopyAndRouteUrl = `${environment.apiUrl}/api/subjectdocument/CopyAndRoute`;

  // Docflowlog urls
  public static readonly OperationBinderDocFlowUrl = `${environment.apiUrl}/api/OperationBinderFolder/GetFileLog`;
  public static readonly SiteDocumentDocFlowUrl = `${environment.apiUrl}/api/SiteDocumentFolder/GetFileLog`;
  public static readonly RegulatoryBinderDocFlowUrl = `${environment.apiUrl}/api/RegulatoryBinderFolder/GetFileLog`;
  public static readonly SubjectRecordDocFlowUrl = `${environment.apiUrl}/api/SubjectRecordsFolder/GetFileLog`;
}

export const userTypes = {
  4: 'Site Administrator',
  3: 'Site User',
  2: 'External User',
};

export const trialRoles = {
  1: 'External User',
  4: 'Principal Investigator',
  5: 'Sub Investigator',
  6: 'Research Coordinator',
  7: 'Data Manager',
};

export const trialStatus = {
  2: 'Active',
  5: 'Archived',
  6: 'Deleted',
  7: 'Closed',
};
