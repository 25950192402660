import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { Message } from 'primeng/api';

import { ApiResponse } from '../../../core/apiresponse';
import { ForgotPasswordService } from '../service/forgotpassword.service';
import { ForgotPassword } from '../model/forgotpassword.model';
import { ForgotPasswordLockUser } from '../model/forgotpassword-lockuser.model';
import { RequestErrors } from 'src/app/common/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-email',
  templateUrl: './forgot-password-email.component.html',
  styleUrls: ['./forgot-password-email.component.scss'],
})
export class ForgotPasswordEmailComponent implements OnInit {
  verifyEmailForm: UntypedFormGroup;
  verifyOTPForm: UntypedFormGroup;

  showEmailNav = true;
  showOtpNav = false;

  forgotPassword: ForgotPassword = {};
  forgotPasswordLockUser: ForgotPasswordLockUser = {};
  apires: ApiResponse;
  msgs: Message[] = [];

  otpAttempts = 3;
  resendOtpAttempts = 3;

  apiresDeactivate: ApiResponse;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  getYear() {
    return new Date().getUTCFullYear();
  }

  cancelForgotPassword() {
    this.router.navigate(['/auth/forgot-password']);
    sessionStorage.clear();
  }

  onSubmit() {
    this.forgotPassword.email = this.verifyEmailForm.controls.email.value;
    this.forgotPassword.requestType = 1;
    this.forgotPassword.resendOTP = false;
    this.forgotPasswordService
      .verifyEmailAddress(this.forgotPassword)
      .subscribe(
        (messageResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === true) {
            this.msgs = [];
            const sucessMsg = 'Check Your Inbox for OTP';
            this.msgs.push({ severity: 'success', detail: sucessMsg });
            this.showOtpNav = !this.showOtpNav;
            this.showEmailNav = !this.showEmailNav;
            this.verifyOTPForm.controls.uId.setValue(this.apires.id);
            sessionStorage.setItem(
              'email',
              this.verifyEmailForm.controls.email.value
            );
          }
          if (this.apires.success === false) {
            this.msgs = [
              {
                severity: 'error',
                detail: this.apires.errorMessages[0],
              },
            ];
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  onSubmitOTP() {
    if (this.otpAttempts > 1) {
      this.forgotPassword.otp = this.verifyOTPForm.controls.otp.value;
      this.forgotPassword.userId = this.verifyOTPForm.controls.uId.value;
      this.forgotPassword.resendOTP = false;
      this.forgotPassword.isFailureAttemptReached = false;

      sessionStorage.setItem('requestType', '1');
      sessionStorage.setItem('userId', this.forgotPassword.userId);

      this.forgotPasswordService.verifyOTP(this.forgotPassword).subscribe(
        (messageResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === true) {
            this.msgs = [];
            this.msgs.push({
              severity: 'success',
              detail: this.apires.message,
            });
            setTimeout(() => {
              this.router.navigate(['/auth/reset-password'], {
                skipLocationChange: true,
              });
            }, 3000);
          }
          if (this.apires.success === false) {
            const errMsg = 'Your OTP is Incorrect.';
            if (this.otpAttempts <= 0) {
              this.msgs = [];
              this.msgs.push({
                severity: 'warn',
                detail: 'Your OTP Attempts Failed. Please Try Again',
              });
            } else {
              this.msgs = [];
              this.msgs.push({
                severity: 'error',
                detail: 'Your OTP is Incorrect',
              });
            }

            this.msgs = [{ severity: 'error', detail: errMsg }];
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    } else {
      this.forgotPassword.otp = this.verifyOTPForm.controls.otp.value;
      this.forgotPassword.userId = this.verifyOTPForm.controls.uId.value;
      this.forgotPassword.resendOTP = false;
      this.forgotPassword.isFailureAttemptReached = true;

      sessionStorage.setItem('requestType', '1');
      sessionStorage.setItem('userId', this.forgotPassword.userId);

      this.forgotPasswordService.verifyOTP(this.forgotPassword).subscribe(
        (messageResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === true) {
            this.msgs = [];

            if ('Given OTP verified successfully' == this.apires.message) {
              this.msgs.push({
                severity: 'success',
                detail: this.apires.message,
              });
              setTimeout(() => {
                this.router.navigate(['/auth/reset-password'], {
                  skipLocationChange: true,
                });
              }, 3000);
            } else {
              this.msgs.push({
                severity: 'error',
                detail: this.apires.message,
              });
              setTimeout(() => {
                this.router.navigate(['/forgot-password'], {
                  skipLocationChange: true,
                });
              }, 12000);
            }
          }
          if (this.apires.success === false) {
            this.msgs = [];
            this.msgs.push({
              severity: 'error',
              detail: this.apires.errorMessages[0],
            });
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    }

    this.otpAttempts = this.otpAttempts - 1;
  }

  resendOTP() {
    this.resendOtpAttempts = this.resendOtpAttempts - 1;
    if (this.resendOtpAttempts < 0) {
      this.msgs = [];
      this.msgs.push({
        severity: 'warn',
        detail: 'Resend OTP attempt failed. Please Try Again',
      });
    } else if (this.resendOtpAttempts > 0) {
      this.forgotPassword.email = sessionStorage.getItem('email');
      this.forgotPassword.requestType = 1;
      this.forgotPassword.resendOTP = true;
      this.forgotPassword.otp = '';
      this.forgotPasswordService
        .verifyEmailAddress(this.forgotPassword)
        .subscribe(
          (messageResponse) => {
            this.apires = messageResponse;
            if (this.apires.success === true) {
              const successMsg = 'Resent OTP, Check Your Inbox';
              this.msgs = [];
              this.msgs.push({ severity: 'success', detail: successMsg });
              this.verifyOTPForm.controls.uId.setValue(this.apires.id);
            } else {
              this.otpAttempts = this.otpAttempts - 1;
              if (this.resendOtpAttempts <= 0) {
                this.msgs = [
                  {
                    severity: 'warn',
                    detail: 'Resend OTP Attempt failed. Please Try Again',
                  },
                ];
              } else {
                this.msgs = [
                  {
                    severity: 'error',
                    detail: 'Your OTP is Incorrect',
                  },
                ];
              }
            }
          },
          (error) => {
            this.msgs = RequestErrors(error).map((x) => {
              return { severity: 'warn', detail: x };
            });
          }
        );
    }
  }

  ngOnInit() {
    this.verifyEmailForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });

    this.verifyOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
      uId: [''],
    });
  }
}
