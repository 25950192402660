import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { FactLidoUserEvents } from 'src/app/notifications/model/notifications.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnChanges {
  @Input() items: FactLidoUserEvents[];
  itemCount = 0;
  constructor(private router: Router) {}

  ngOnChanges() {
    this.itemCount = this.items.length > 0 ? this.items[0].nCount : 0;
  }

  onEventClick(userEvent?: FactLidoUserEvents) {
    if (userEvent === undefined) {
      this.router.navigate(['/layout/notifications/notifications-list']);
    } else {
      this.router.navigate([
        '/layout/notifications/notifications-list',
        userEvent.userEventId,
      ]);
    }
  }
}
