import { Component, OnInit } from '@angular/core';
import { NavMenuService } from './components/services/nav-menu.service';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';

import { filter } from 'rxjs/operators';
import { AppAccessService } from '../core/directives/access.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  trialSelectedName = sessionStorage.getItem('trialName');
  isTrialSelected = sessionStorage.getItem('trialId') !== null;
  isSiteHome: boolean = sessionStorage.getItem('isSiteHome') === 'Yes';

  toggleicon = false;
  showSiteDocs = false;
  numberOfSites = Number(sessionStorage.getItem('numberOfSites'));

  constructor(
    private navMenuService: NavMenuService,
    private encrDecrService: EncrDecrService,
    private router: Router,
    private appAccessService: AppAccessService
  ) {}

  ngOnInit() {
    const trialName = sessionStorage.getItem('trialName');
    const trialId = Number(sessionStorage.getItem('trialId'));
    if (this.isTrialSelected) {
      this.navMenuService.setTrialState(true, trialName, trialId);
    }
    this.navMenuService.trialState$.subscribe((trialDetails) => {
      this.isTrialSelected = trialDetails.trialSelected;
      this.trialSelectedName = trialDetails.trialName;
    });
    this.navMenuService.siteHomeChanged$.subscribe(() => {
      this.isSiteHome = sessionStorage.getItem('isSiteHome') === 'Yes';
    });
    this.navMenuService.userTypeChanged$.subscribe(() => {
      this.showSiteDocs = this.appAccessService.hasAccess('Site Docs');
    });
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        if (ev.url === '/layout/sitehome') {
          // user has navigated to sitehome
          this.navMenuService.setIsSiteHome(true);
          this.navMenuService.setTrialState(false);
          this.isSiteHome = true;
        } else if (ev.url === '/layout/home') {
          // user has navigated to home
          this.navMenuService.setIsSiteHome(false);
          this.navMenuService.setTrialState(false);
          this.isSiteHome = false;
          this.isTrialSelected = false;
        }
      });
  }

  clickSiteHome() {
    if (this.numberOfSites > 1) {
      this.router.navigate(['/layout/sitehome']);
    } else {
      this.router.navigate(['/layout/home']);
    }
  }

  goToTrial() {
    this.router.navigate(['/layout/trial-vendors']);
  }

  addRequest(requestType: string) {
    const reqTypeValue = this.encrDecrService.encryptData(requestType);
    this.router.navigate(['/layout/faq/addrequest'], {
      queryParams: {
        reqType: reqTypeValue,
      },
    });
  }

  goToHelpList() {
    this.router.navigate(['/layout/faq/helprequestlist']);
  }

  helpHomePage() {
    this.router.navigate(['/layout/faq/help']);
  }
}
