import { AbstractControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';

export class ResetRegistrationValidator {
  static validate(registrationFormGroup: UntypedFormGroup) {
    const changedPassword = registrationFormGroup.controls.password.value;
    const currentPassword = registrationFormGroup.controls.repeatPassword.value;

    if (currentPassword !== changedPassword) {
      return {
        doesMatchPassword: true,
      };
    }
    return null;
  }

  static pswvalidate(control: AbstractControl) {
    const password = control.value;
    const isValid =
      /(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/.test(
        password
      );
    if (isValid === false) {
      return {
        doesChkPassword: true,
      };
    }
    return null;
  }
}
