import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './auth/auth-guard.service';
import { LoginComponent } from './auth/login/login.component';

import { SiteHomeComponent } from './sitehome/sitehome.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ForgotPasswordEmailComponent } from './auth/forgot-password/forgot-password-email/forgot-password-email.component';
import { ForgotPasswordSecquestionsComponent } from './auth/forgot-password/forgot-password-secquestions/forgot-password-secquestions.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ProfileGuard } from './auth/profile-guard.service';
import { TermsOfUseGuard } from './auth/tos-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth/forgot-password-email',
    component: ForgotPasswordEmailComponent,
  },
  {
    path: 'auth/forgot-password-secquestions',
    component: ForgotPasswordSecquestionsComponent,
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'layout', // should be layout
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'doc-flow-log',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./doc-flow-log/doc-flow-log.module').then(
            (m) => m.DocFlowLogModule
          ),
      },
      {
        path: 'faq',
        canActivate: [AuthGuard],
        loadChildren: () => import('./FAQ/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'home',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'notifications',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'operations-binder',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./operations-binder/operations-binder.module').then(
            (m) => m.OperationsBinderModule
          ),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard, TermsOfUseGuard],
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'regulatory-binder',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./regulatory-binder/regulatory-binder.module').then(
            (m) => m.RegulatoryBinderModule
          ),
      },
      {
        path: 'site-documents',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./site-documents/site-documents.module').then(
            (m) => m.SiteDocumentsModule
          ),
      },
      {
        path: 'sitehome',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        component: SiteHomeComponent,
      },
      {
        path: 'subject',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./subject/subject.module').then((m) => m.SubjectModule),
      },
      {
        path: 'terms-of-use',
        canActivate: [AuthGuard],
        component: TermsOfUseComponent,
      },
      {
        path: 'trial-vendors',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./trialvendors/trial-vendors.module').then(
            (m) => m.TrialVendorsModule
          ),
      },
      {
        path: 'users',
        canActivate: [AuthGuard, TermsOfUseGuard, ProfileGuard],
        loadChildren: () =>
          import('./users/user-list.module').then((m) => m.UserListModule),
      },
    ],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
