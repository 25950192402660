import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { FactLidoUserEvents } from 'src/app/notifications/model/notifications.model';
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnChanges {
  @Input() items: FactLidoUserEvents[];
  itemCount = 0;

  constructor(private router: Router) {}

  ngOnChanges() {
    this.itemCount = this.items.length > 0 ? this.items[0].nCount : 0;
  }

  onEventClick(userEvent?: FactLidoUserEvents) {
    // Zero out the counter because they have gone to the notification screen
    if (userEvent === undefined) {
      this.router.navigate(['/layout/notifications', 'alerts-list']);
    } else {
      this.router.navigate([
        '/layout/notifications',
        'alerts-list',
        userEvent.userEventId,
      ]);
    }
  }
}
