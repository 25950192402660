export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public Psso = null;
  public apiUrl = '';

  // Whether or not to enable debug mode
  public enableDebug = true;
}
