<!-- alerts -->
<span class="rtmenu">
  <div class="d-flex flex-row align-items-center">
    <a *ngIf="isSiteHome === false" class="dropdown-toggle marright" pTooltip="Help" data-bs-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false" tooltipPosition="bottom"><img
        src="/assets/images/common/question-mark.svg" width="15" class="qico"></a>
    <div class="dropdown-menu dropdown-menu-end dropdown-panel">
      <div class="row">
        <div class="col-md-12">
          <div class="hshd">Need help?</div>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="row">
        <div class="col-md-4 text-center"><img (click)="addRequest('AddRequest')" class="himg cursorpointer"
            src="/assets/images/common/message.png"></div>
        <div class="col-md-4 text-center"><img (click)="goToHelpList()" class="himg cursorpointer"
            src="/assets/images/common/ticket.png"></div>
        <div class="col-md-4 text-center"><img (click)="helpHomePage()" class="himg cursorpointer"
            src="/assets/images/common/faq.png"></div>
      </div>
      <div class="spacer2"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="hdesc">Message us, View your help requests, or search our FAQs.</div>
        </div>
      </div>
    </div>
    <app-alerts *ngIf="isSiteHome !== true" [items]="alerts"></app-alerts>
    <app-notifications *ngIf="isSiteHome !== true" [items]="notifications"></app-notifications>
    <app-profile-layout [showUserManagement]="showUserManagement"></app-profile-layout>
  </div>
</span>
