import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  constructor(private router: Router) {}

  getYear() {
    return new Date().getUTCFullYear();
  }

  cancelForgotPassword() {
    this.router.navigate(['/auth/login']);
  }
}
