import { NgModule } from '@angular/core';

import { ForgotPasswordEmailComponent } from './forgot-password/forgot-password-email/forgot-password-email.component';
import { ForgotPasswordSecquestionsComponent } from './forgot-password/forgot-password-secquestions/forgot-password-secquestions.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'forgot-password-email',
    component: ForgotPasswordEmailComponent,
  },
  {
    path: 'forgot-password-secquestions',
    component: ForgotPasswordSecquestionsComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
