<div class="qhd">Agreement to Terms</div>

<div class="qans">These Website Terms of Use (“Terms”) describe the rules for using this Website and the content thereon
  (“Website”). These Terms constitute a legally binding agreement (“Agreement”) between you, the user of this Website,
  and LIDO™. Please note that LIDO™ does not provide medical care or advice.
  By using this Website, you agree to the most recent Terms as well as the most recent version of our Privacy Policy as
  found on <a href="https://lido.io" target="_new">www.lido.io</a>. We may change these Terms at any time, and such
  changes will be posted on <a href="https://app.lido.io" target="_new">https://app.lido.io</a>. It is your
  responsibility to review these Terms each time you use this Website. By continuing to use this Website, you indicate
  that you have read and agree to the Privacy Policy and that you consent to any changes to the Terms.
  By using this Website, you acknowledge and agree that LIDO™ may monitor any activity on this Website at any time, and
  that LIDO™ may use the results of such monitoring without limitation, subject to applicable law.
  These Terms are in addition to any other agreement you may have entered into with LIDO™. </div>

<div class="qhd">License to Use Website and Content Ownership</div>

<div class="qans">Subject to these Terms, LIDO™ grants you a personal, nontransferable, nonexclusive, revocable, limited
  license to the content on the Website for the sole purpose of collecting information regarding and accessing our
  clinical trial services. Any other use (including the reproduction, modification, distribution, transmission,
  republication, display, or performance of any content on this Website) is strictly prohibited without prior written
  approval of LIDO™.
  All rights, title, and interest in and to the Website and all intellectual property rights, including all copyright,
  trademark, patent and trade secret rights therein shall remain with LIDO™ (or our licensors and vendors). No ownership
  interest is transferred to you or any other entity by virtue of making the content available on the Website, granting
  the foregoing license, or entering into this Agreement.
  In the event you choose to provide us with any feedback, suggestions, or similar communications, all such messages
  (collectively, “Feedback”) will be considered non-personal, non-confidential (except for personal information as
  described in our Privacy Policy) and nonproprietary. You hereby grant LIDO™ a perpetual, sublicensable, assignable,
  unrestricted, worldwide, royalty-free, irrevocable license to use, reproduce, display, perform, practice, modify,
  create derivative or collective works, transmit and distribute your Feedback, in whole or in part, and including any
  and all intellectual property rights therein. You agree that the provisions of this paragraph will survive any
  termination of this Agreement.</div>

<div class="qhd">Restrictions on Use of this Website</div>

<div class="qans">You are responsible for your behavior while using this Website and for respecting LIDO™’s and third
  parties’ rights in connection with the Website.
  LIDO™ requires you to enter your username and password each time you login. You are responsible for maintaining the
  confidentiality and security of your username and password. </div>

<div class="qans">
  You agree:<br />
  <ul>
    <li>not to use this Website in any way not explicitly permitted by these Terms or the content of the Website;</li>
    <li>not to disclose any confidential or private information you may access using the Website, except as authorized.
    </li>
    <li>not to use this Website whether to transmit, distribute, or store any infringing, threatening, defamatory,
      libelous, obscene, pornographic, invasive of privacy, abusive, or otherwise objectionable material;</li>
    <li>not to use or exploit any portion of this Website to distribute commercial messages, “spam,” or other
      unsolicited communications.</li>
    <li>not to copy, modify, or create derivative works from the Website;</li>
    <li>not to misrepresent your identity or provide any false information;</li>
    <li>not to take any action intended to interfere with the operation of this Website;</li>
    <li>not to take any action that imposes or may impose an unreasonable or disproportionately large load on LIDO™’s
      network infrastructure, as determined by LIDO™ in its sole discretion </li>
    <li>not to access or attempt to access any portion of this Website to which you have not been explicitly granted
      access;</li>
    <li>not to share any password assigned to or created by you with any third parties or use any password granted to or
      created by a third party;</li>
    <li>to immediately notify LIDO™ of any known or suspected unauthorized use of your username or password;</li>
    <li>to comply with all applicable laws and regulations while using this Website.</li>
  </ul>
</div>

<div class="qans">
  You further represent and warrant:<br />
  <ul>
    <li>you are at least 18 years of age;</li>
    <li>you are not debarred under section 306(a) and (b) of the Federal Food, Drug and Cosmetic Act or any other
      applicable regulatory authority.</li>
  </ul>
</div>

<div class="qhd">Changes and Termination</div>

<div class="qans">We may change, add, or remove some or all of the content on this Website at any time.
  We may terminate this license at any time for any reason. If you breach any of these Terms, your license to the
  Website content terminates immediately. Upon the termination of this license you must stop using this Website.
  LIDO™ may, but is not obligated to, restrict or remove any content that we determine in our sole discretion
  violates these Terms or is otherwise harmful to us, our customers, or any third party. We reserve the right to
  remove the content you provide at any time, but you understand that we may preserve and access a backup-copy, and
  we may disclose the content if required to do so by law or in a good faith belief that such access, preservation,
  or disclosure is required by law or in the best interest of LIDO. </div>

<div class="qhd">Copyright Infringement – DMCA Notice</div>

<div class="qans">The Digital Millennium Copyright Act of 1998 (“DMCA”) provides recourse for copyright owners who
  believe that material appearing on the internet infringes their rights under U.S. copyright law. If you believe in
  good faith that content or material on this Website infringes a copyright you own, you may send LIDO™ a notice of
  infringement. To file such a notice with LIDO™, the requirements specified in Title II of the DMCA must be met;
  the text of this statute can be found at the U.S. Copyright Office website, <a href="http://www.copyright.gov/"
    target="_new">http://www.copyright.gov.</a>. This notice should be sent to: <a
    href="mailto: legal@lido.io">legal&#64;LIDO.io</a>. </div>

<div class="qhd">Third-Party Links</div>

<div class="qans">While visiting this Website you may click on a link to other websites and leave this Website.
  LIDO™ neither endorses nor is responsible for the content, accuracy, and accessibility of linked websites operated
  by third parties. You are solely responsible for your dealings with such third parties, which will be governed
  independently by the third-parties’ privacy policies and terms of use as applicable.</div>

<div class="qhd">NO WARRANTIES</div>

<div class="qans">THIS WEBSITE IS PROVIDED TO YOU ON AN “AS IS” and “AS AVAILABLE” BASIS. LIDO™ — INCLUDING ALL
  OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS — HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR
  IMPLIED, STATUTORY OR OTHERWISE INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
  FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
  LIDO™ MAKES NO WARRANTY AS TO THE ACCURACY, COMPLETENESS, TIMELINESS, CORRECTNESS, OR RELIABILITY OF ANY CONTENT
  AVAILABLE THROUGH THIS WEBSITE. LIDO™ MAKES NO REPRESENTATIONS OR WARRANTIES THAT USE OF THIS WEBSITE WILL BE
  UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEBSITE OR THE TECHNOLOGY THAT MAKES IT
  AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ARE RESPONSIBLE FOR TAKING ALL PRECAUTIONS
  NECESSARY TO ENSURE THAT ANY CONTENT YOU MAY OBTAIN FROM THIS WEBSITE IS FREE OF VIRUSES AND ANY OTHER POTENTIALLY
  DESTRUCTIVE COMPUTER CODE. THE USE OF THIS WEBSITE IS SOLELY AT YOUR OWN RISK.</div>

<div class="qhd">LIMITATION OF LIABILITY</div>

<div class="qans">YOU EXPRESSLY UNDERSTAND AND AGREE THAT LIDO™ SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
  INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES—INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS
  OF PROFITS, GOODWILL, USE, DATA, OR OTHER LOSSES (EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) BASED ON
  WARRANTY, CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY—RESULTING FROM ANY MATTER RELATED TO YOUR
  USE OF OR INABILITY TO USE THIS WEBSITE. LIDO™ EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR THE ACTIONS OF ANY OTHER
  USER OF THIS WEBSITE. IF YOU ARE DISSATISFIED WITH THIS WEBSITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
  USING THE WEBSITE.</div>

<div class="qhd">Governing Law, Exhaustion of Remedies, and Statute of Limitations</div>

<div class="qans">The laws of the state of Washington, without reference to its choice-of-law principles, govern
  these Terms and any cause of action arising under or relating to your use of the Website. You agree that the only
  proper jurisdiction and venue for any dispute with LIDO™ in any way relating to these Terms or your use of this
  Website is in the state and federal courts in Washington, U.S. You further agree and consent to the exercise of
  personal jurisdiction in these courts in connection with any dispute involving LIDO™ or its employees, officers,
  directors, and agents.
  Before seeking legal recourse for any harm you believe you have suffered arising from or related to your use of
  this Website, you agree to inform LIDO™ in writing and to give us 30 days to cure the harm before initiating any
  action.
  Any claim or cause of action arising out of or related to the Website or these Terms must be filed within one year
  after such claim or cause of action arose.</div>

<div class="qhd">Miscellaneous</div>

<div class="qans">There is no agency, partnership, or joint venture relationship between any user and LIDO™ arising
  solely through the use of this Website. If any provision of these Terms is found to be invalid by any court having
  competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions
  of these Terms, which shall remain in full force and effect. The failure of LIDO™ to exercise or enforce any right
  or provision of these Terms shall not constitute a waiver of such right or condition. You agree to defend and
  indemnify, LIDO™ and their affiliates, officers, directors, employees, and agents, harmless from any claim,
  demand, or damage, including reasonable attorneys' fees, arising out of or related to your breach of this
  Agreement or your use or misuse of this Website. You may not transfer or assign any rights or obligations under
  this Agreement. LIDO™ may transfer or assign its rights and obligations under this Agreement.</div>

<div class="qhd">Contact Us</div>

<div class="qans">To contact us about these Terms or the operation of this Website, email us at <a
    href="mailto: privacy@lido.io">privacy&#64;LIDO.io</a>. </div>

<div class="qhd">Effective Date</div>

<div class="qans">The Effective Date of these Terms is January 1, 2024.</div>