import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  /*   counter = 1800;

  event: Observable<any>;
  timer = interval(1000).pipe(
    take(this.counter),
    tap(() => {
      --this.counter;
      if (this.counter === 0) {
        sessionStorage.clear();
        this.stopInterval();
        this.router.navigate(
          ['/auth/login', { sInf: 'Session Expired. Please Login Again' }],
          {
            skipLocationChange: true,
          }
        );
      }
    })
  ); */
  // subscription: Subscription = this.timer.subscribe();

  constructor() {
    // this.stopInterval();
  }
  /*   stopInterval() {
    this.subscription.unsubscribe();
  } */

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwt = JSON.parse(sessionStorage.getItem('currentUser'));

    const headers = req.headers
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .set('authorization', 'Bearer ' + jwt);

    const authRequest = req.clone({ headers });
    return next.handle(authRequest);

    /*     return next.handle(authRequest).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200) {
            this.subscription.unsubscribe();
            this.counter = 1800;
            this.subscription = this.timer.subscribe();
          } else if (event.status === 401) {
            console.error('Event Status:', event.status);
            this.router.navigate(['/layout/access-denied']);
          } else if (event.status === 404) {
            console.error('not found 404 event.status', event.status);
            this.router.navigate(['/layout/page-not-found'], {});
          }
        }
      })
    ); */
  }
}
