<header>
    <div class="fplogonav">
        <img class="logo" src="/assets/images/common/logo.svg">
    </div>
</header>

<div class="container fpcontainer">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="progress">
            <div class="progress-bar" role="progressbar" style="width:90%" aria-valuenow="90" aria-valuemin="0"
                aria-valuemax="100"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">Identify</div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">Verify</div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">Complete</div>
        </div>

        <div class="msgnav">
            <p-messages [value]="msgs"></p-messages>
        </div>

        <div class="fpmainnav">
            <div class="hd">Reset password</div>
            <div class="formnav">




                <div class="row my-3 row align-items-center">
                    <label class="col-3">New Password</label>
                    <div class="col-8">
                        <div class="p-inputgroup">
                            <input type="password" pPassword class="form-control" [showPassword]="passwordVisible"
                                placeholder="Password" feedback="false" formControlName="password">
                            <span (click)="passwordVisible = !passwordVisible" *ngIf="!passwordVisible"
                                class="p-inputgroup-addon"><i class="fa fa-eye"></i></span>
                            <span (click)="passwordVisible = !passwordVisible" *ngIf="passwordVisible"
                                class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>
                        </div>
                    </div>
                    <div class="alert alert-danger"
                        *ngIf="form.get('password').invalid && form.controls.password.dirty && form.controls.password.touched">
                        Password should be Minimum 6 and Maximum 12 Characters, at least one UPPERCASE letter,
                        one Lowercase
                        letter, one number and one special character.
                    </div>

                </div>



                <div class="row my-3 row align-items-center">
                    <label class="col-3">New Password</label>
                    <div class="col-8">
                        <div class="p-inputgroup">
                            <input type="password" pPassword class="form-control" [showPassword]="repeatPasswordVisible"
                                placeholder="Confirm Password" feedback="false" formControlName="repeatPassword">
                            <span (click)="repeatPasswordVisible = !repeatPasswordVisible"
                                *ngIf="!repeatPasswordVisible" class="p-inputgroup-addon"><i
                                    class="fa fa-eye"></i></span>
                            <span (click)="repeatPasswordVisible = !repeatPasswordVisible" *ngIf="repeatPasswordVisible"
                                class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>
                        </div>
                    </div>
                    <div class="alert alert-danger"
                        *ngIf="form.get('repeatPassword').invalid && form.controls.repeatPassword.dirty && form.controls.repeatPassword.touched">
                        Confirm Password is Required</div>
                    <div class="alert alert-danger" *ngIf="form.hasError('doesMatchPassword')">
                        Passwords
                        do not match</div>
                </div>

            </div>

            <div class="btnnav">
                <button type="submit" [disabled]="!form.valid" class="btn btn-primary marright">Reset</button>
                <button type="button" (click)="cancelResetPassword()" class="btn btn-default">Cancel</button>
            </div>
        </div>
    </form>
</div>

<footer class="footer">
    <div class="container-fluid text-right">
        <div class="d-flex flex-row justify-content-end">
            {{getYear()}} &copy; | <a href="http://lido.io" target="_blank">Clinical Trial Operating System</a>
        </div>
    </div>
</footer>