<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-header justify-content-between px-3">
  <div class="my-0 mr-md-auto font-weight-normal">
    <img class="inlogo" id="clicksitehome" appAccess="Site Home" (click)="clickSiteHome()"
      src="/assets/images/common/app-logo.png" />
  </div>
  <nav class="my-2 my-md-0 mr-md-3">
    <app-header></app-header>
  </nav>
</nav>

<div>
  <div class="ng-sidebar">
    <div class="ltmenu">
      <div class="list-group">
        <span>
          <a id="gotositehome" *ngIf="numberOfSites > 1" routerLink="/layout/sitehome" appAccess="Site Home"
            routerLinkActive="list-group-item-active" class="list-group-item">
            <fa name="stethoscope" *ngIf="!toggleicon" class="marright"></fa>Site Home
            <fa name="stethoscope" *ngIf="toggleicon" class="rticon" pTooltip="Site Home" tooltipPosition="right"></fa>
          </a>
          <a id="gotositedocuments" *ngIf="!isSiteHome && showSiteDocs" routerLink="/layout/site-documents"
            routerLinkActive="list-group-item-active" class="list-group-item">
            <fa name="file-text" class="marright"></fa>Site Documents<fa name="file-text-o" *ngIf="toggleicon"
              class="rticon" pTooltip="Site Documents" tooltipPosition="right"></fa>
          </a>
          <a id="gotohome" *ngIf="!isSiteHome && numberOfSites > 0" routerLink="/layout/home"
            routerLinkActive="list-group-item-active" appAccess="Home" class="list-group-item">
            <fa name="home" class="marright"></fa>Trial Home<fa name="home" *ngIf="toggleicon" class="rticon"
              pTooltip="Trial Home" tooltipPosition="right"></fa>
          </a>
        </span>
      </div>
      <ng-container *ngIf="!isSiteHome">
        <div *ngIf="isTrialSelected === true" role="button" class="list-group submenu">
          <span routerLink="/layout/trial-vendors" class="trialname">
            <fa name="flask" class="marright"></fa><span *ngIf="!toggleicon">{{ trialSelectedName }}</span>
            <fa name="flask" *ngIf="toggleicon" class="rticon" pTooltip="{{ trialSelectedName }}"
              tooltipPosition="right">
            </fa>
          </span>
          <a id="gotooperationsbinder" appAccess="Folder List" routerLink="/layout/operations-binder"
            routerLinkActive="list-group-item-active" class="list-group-item">
            <fa name="book" class="marright"></fa>Operations Binder<fa name="book" *ngIf="toggleicon" class="rticon"
              pTooltip="Operations Binder" tooltipPosition="right"></fa>
          </a>
          <a id="gotoregulatorybinder" routerLink="/layout/regulatory-binder" appAccess="Folder List"
            routerLinkActive="list-group-item-active" class="list-group-item">
            <fa name="book" class="marright"></fa>Regulatory Binder<fa name="book" *ngIf="toggleicon" class="rticon"
              pTooltip="Regulatory Binder" tooltipPosition="right"></fa>
          </a>
          <a id="gotosubjectrecords" routerLink="/layout/subject" appAccess="Folder List"
            routerLinkActive="list-group-item-active" class="list-group-item">
            <fa name="user" class="marright"></fa>Subject Records<fa name="user" *ngIf="toggleicon" class="rticon"
              pTooltip="Subject Records" tooltipPosition="right"></fa>
          </a>
        </div>
      </ng-container>

      <div class="helpnav" pTooltip="Help" tooltipPosition="right">
        <a id="helptoggle" (click)="op1.toggle($event)"><img src="/assets/images/common/question-mark.svg"
            width="50" /></a>
      </div>
    </div>
  </div>
  <div class="ng-sidebar-content">
    <section class="{{ toggleicon === true ? 'contents_icos' : 'contents' }}">
      <router-outlet></router-outlet>
      <app-layout-footer></app-layout-footer>
    </section>
  </div>
</div>

<p-overlayPanel #op1>
  <div class="row">
    <div class="col-md-12">
      <div class="hshd">Need help?</div>
    </div>
  </div>
  <div class="spacer"></div>
  <div class="row">
    <div class="col-md-4 text-center">
      <img id="addrequestemail" (click)="addRequest('AddRequest')" class="himg cursorpointer"
        src="/assets/images/common/message.png" />
    </div>
    <div class="col-md-4 text-center">
      <img id="addrequestcallme" (click)="goToHelpList()" class="himg cursorpointer"
        src="/assets/images/common/ticket.png" />
    </div>
    <div class="col-md-4 text-center">
      <img id="gotohelp" (click)="helpHomePage()" class="himg cursorpointer" src="/assets/images/common/faq.png" />
    </div>
  </div>
  <div class="spacer2"></div>
  <div class="row">
    <div class="col-md-12">
      <div class="hdesc">
        Message us, View your help requests, or search our FAQs.
      </div>
    </div>
  </div>
</p-overlayPanel>