import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './auth/auth-http-error-interceptor';
import { AuthHttpInterceptor } from './auth/auth-http-interceptor';
import { AuthModule } from './auth/auth.module';
import { EnvServiceProvider } from './env-service/env.service.provider';
import { LayoutModule } from './layout/layout.module';

import { WorkFlowRoutingModule } from './workflow-routing/workflow-routing.module';
import { TermsOfUseModule } from './terms-of-use/terms-of-use.module';
import { SharedModule } from './shared/shared.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { SiteHomeModule } from './sitehome/sitehome.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    LayoutModule,
    AuthModule,
    WorkFlowRoutingModule,
    SiteHomeModule,
    TermsOfUseModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    EnvServiceProvider,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
