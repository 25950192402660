import { HttpErrorResponse } from '@angular/common/http';
import {
  AppConstants,
  trialRoles,
  trialStatus,
  userTypes,
} from './app.constants';
import ssoConfig from './sso.config';
import { MenuItem } from 'primeng/api';
import { BinderDocumentType } from '../documents/model/document-add.model';
import { environment } from 'src/environments/environment';

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

// Call this to authenticate the user with adobe and get the adobe access token
// and adobe api url
export function adobeAuth() {
  const url = `${environment.apiUrl}/api/adobe/callback`;
  const clientId = environment.adobeClientId;
  const scope =
    'user_read:account+user_write:account+user_login:account+agreement_read:account+agreement_write:account+agreement_send:account+widget_read:account+widget_write:account+library_read:account+library_write:account+workflow_read:account+workflow_write:account';
  const responseType = 'code';
  const adobeAuthUrl = environment.adobeAuthUrl;
  const authorizationUrl = `${adobeAuthUrl}?client_id=${clientId}&redirect_uri=${url}&response_type=${responseType}&scope=${scope}`;

  // Redirect the user to the authorization URL
  const authWindow: Window = window.open(authorizationUrl, '_blank');
  window.addEventListener('message', handleOAuthMessage, false);
  const authWindowInterval = setInterval(function () {
    if (authWindow.closed) {
      clearInterval(authWindowInterval);
      window.removeEventListener('message', handleOAuthMessage, false);
    }
  }, 1000);
}

function handleOAuthMessage(event) {
  try {
    // TODO: Put all sessionStorage keys in appConstants
    const tokenResponse = JSON.parse(event.data);
    if (tokenResponse.access_token) {
      sessionStorage.setItem(
        AppConstants.AdobeAccessTokenKey,
        tokenResponse.access_token
      );
      sessionStorage.setItem(
        AppConstants.AdobeApiUrlKey,
        tokenResponse.api_access_point
      );
      window.removeEventListener('message', handleOAuthMessage, false);
      return;
    }
  } catch (e) {
    if (sessionStorage.getItem(AppConstants.AdobeAccessTokenKey) !== null) {
      window.removeEventListener('message', handleOAuthMessage, false);
    }
  }
}

export function getFileNameParts(fileName: string) {
  const lastDotIndex = fileName.lastIndexOf('.');
  const fileNameWithoutExt =
    lastDotIndex !== -1 ? fileName.substring(0, lastDotIndex) : fileName;
  const fileExt =
    lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : '';
  return { fname: fileNameWithoutExt, extension: fileExt };
}

export function getSignedDocPath(docPath: string) {
  // If docPath is "C:\tmp\foo.pdf"
  const lastSlashIndex = docPath.lastIndexOf('/');
  const directoryName =
    lastSlashIndex !== -1 ? docPath.substring(0, lastSlashIndex) : '';
  const fileName = docPath.substring(lastSlashIndex + 1); // Returns "foo.pdf"
  return `${directoryName}/signed${fileName}`; // Returns "C:\tmp\signedfoo.pdf"
}

export function makeBreadCrumbs(trialSelected = false, endLabel = '') {
  const bookmarkItems: MenuItem[] = [];
  const numberOfSites = Number(sessionStorage.getItem('numberOfSites'));

  if (numberOfSites > 1) {
    bookmarkItems.push({
      label: 'Site Home',
      routerLink: '/layout/sitehome',
    });
  }

  if (sessionStorage.getItem('isSiteHome') !== 'Yes') {
    bookmarkItems.push({
      label: sessionStorage.getItem('siteName'),
      routerLink: '/layout/home',
    });

    if (trialSelected) {
      bookmarkItems.push({
        label: sessionStorage.getItem('trialName'),
        routerLink: '/layout/trial-vendors',
      });
    }
  }
  if (endLabel !== '') {
    bookmarkItems.push({
      label: endLabel,
    });
  }

  return bookmarkItems;
}

export function makeBreadCrumbsDocuments(
  binderDocumentType: BinderDocumentType,
  subjectId?: number
) {
  const bookmarkItems: MenuItem[] = makeBreadCrumbs(
    binderDocumentType !== BinderDocumentType.sitedocument
  );
  switch (binderDocumentType) {
    case BinderDocumentType.sitedocument:
      bookmarkItems.push({
        label: 'Site Documents',
        routerLink: '/layout/site-documents',
      });
      break;
    case BinderDocumentType.operationsbinder:
      bookmarkItems.push({
        label: 'Operations Binder',
        routerLink: '/layout/operations-binder',
      });
      break;
    case BinderDocumentType.regulatorybinder:
      bookmarkItems.push({
        label: 'Regulatory Binder',
        routerLink: '/layout/regulatory-binder',
      });
      break;
    case BinderDocumentType.subjectdocument:
      bookmarkItems.push({
        label: 'Subjects',
        routerLink: '/layout/subject',
      });
      bookmarkItems.push({
        label: sessionStorage.getItem('subjectName'),
        routerLink: `/layout/subject/subject-documents/${subjectId}`,
      });
      break;
  }
  return bookmarkItems;
}

export function makeBreadCrumbItemsHelp(
  atFaqRoot: boolean,
  trialSelected = false
) {
  const bookmarkItems: MenuItem[] = makeBreadCrumbs(trialSelected);

  if (atFaqRoot) {
    bookmarkItems.push({
      label: 'FAQs',
    });
  } else {
    bookmarkItems.push({
      label: 'FAQs',
      routerLink: '/layout/faq/help',
    });
  }
  return bookmarkItems;
}

export function checkForPlugin() {
  sessionStorage.setItem('Oktaplugin', '');

  return new Promise((resolve) => {
    window.addEventListener('message', function (event) {
      if (
        event.origin === ssoConfig.oidc.pluginBaseUrl &&
        event.data &&
        event.data.detected
      ) {
        sessionStorage.setItem('Oktaplugin', 'Y');
        resolve('Y');
      } else if (
        event.origin === ssoConfig.oidc.pluginBaseUrl &&
        event.data &&
        event.data.detected === false
      ) {
        sessionStorage.setItem('Oktaplugin', 'N');
        resolve('N');
      }
    });
  });
}

export function getUserType(userTypeId: number) {
  return Object.prototype.hasOwnProperty.call(userTypes, userTypeId)
    ? userTypes[userTypeId]
    : 'Unknown user type';
}

export function getTrialStatus(trialStatusId: number) {
  return Object.prototype.hasOwnProperty.call(trialStatus, trialStatusId)
    ? trialStatus[trialStatusId]
    : 'Unknown trial status';
}

export function getUserRole(userRoleId: number) {
  return Object.prototype.hasOwnProperty.call(trialRoles, userRoleId)
    ? trialRoles[userRoleId]
    : 'Unknown trial role';
}

export function getCurrentUserType() {
  const userTypeId = Number(sessionStorage.getItem('userTypeId'));
  let userType = undefined;
  if (Object.prototype.hasOwnProperty.call(userTypes, userTypeId)) {
    userType = userTypes[userTypeId];
  }
  return userType;
}

export function getCurrentUserRole() {
  const userRoleId = Number(sessionStorage.getItem('userRoleId'));
  let userRole = undefined;
  if (Object.prototype.hasOwnProperty.call(trialRoles, userRoleId)) {
    userRole = trialRoles[userRoleId];
  } else {
    userRole = undefined;
  }
  return userRole;
}

export function updateCurrentUserRole(trialRoleId: number) {
  sessionStorage.setItem('userRoleId', trialRoleId.toString());
}

export function RequestErrors(error: HttpErrorResponse): string[] {
  const result: string[] = [];
  if (error.message)
    if (error.error !== undefined) {
      result.push(error.error);
      return result;
    }
  if (error.message !== undefined) {
    result.push(error.message);
  }

  return result;
}

// From https://stackoverflow.com/questions/1497481/javascript-password-generator?rq=3
export function password_generator(passwordLength = 8) {
  const validChars = 'abcdefghijklmnopqrstuvwxyz'; //to upper
  const validNumeric = '0123456789';
  const validSpecialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let password = '';
  let currentCharacter = '';

  while (password.length < passwordLength) {
    const entity1 = Math.ceil(
      validChars.length * Math.random() * Math.random()
    );
    const entity2 = Math.ceil(
      validNumeric.length * Math.random() * Math.random()
    );
    const entity3 = Math.ceil(
      validSpecialChars.length * Math.random() * Math.random()
    );
    let hold = validChars.charAt(entity1);
    hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
    currentCharacter += hold;
    currentCharacter += validNumeric.charAt(entity2);
    currentCharacter += validSpecialChars.charAt(entity3);
    password = currentCharacter;
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  return password;
}
