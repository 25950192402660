import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard {
  constructor(private router: Router) {}
  canActivate(): boolean {
    const isSignupVerified =
      sessionStorage.getItem('isSignupVerified') === 'true';
    const isProfileVerified: boolean =
      sessionStorage.getItem('isProfileVerified') === 'true';

    if (isSignupVerified === false || isProfileVerified === false) {
      this.router.navigate(['/layout/profile']);
      return false;
    }
    return true;
  }
}
