import { Component, OnInit } from '@angular/core';

import { MenuItem, Message } from 'primeng/api';
import { SiteHome } from '../home/model/sitehome.model';
import { UserService } from '../users/service/user.service';
import { ApiResponse } from '../core/apiresponse';
import { NavMenuService } from '../layout/components/services/nav-menu.service';
import { AppConstants } from '../common/app.constants';
import { RequestErrors } from '../common/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sitehome',
  templateUrl: './sitehome.component.html',
  styleUrls: ['./sitehome.component.scss'],
})
export class SiteHomeComponent implements OnInit {
  sortOptions = [
    { label: 'Name A to Z', value: 'siteName' },
    { label: 'Name Z to A', value: '!siteName' },
  ];
  sortKey: string;
  sortField: string;
  sortOrder: number;
  sites: SiteHome[];
  msgs: Message[];
  pMenuItems: MenuItem[] = [
    {
      label: 'Site Home',
    },
  ];
  apires: ApiResponse;
  initialView = AppConstants.vendorFlowView;
  defaultSiteLogo = AppConstants.defaultSiteLogo;
  currentView = AppConstants.vendorFlowView;
  recsPerPage = AppConstants.gridViewRecsPerPage;
  pageLinksPerPage = AppConstants.docListPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;

  constructor(
    private userService: UserService,
    private router: Router,
    private navService: NavMenuService
  ) {}

  onLayoutChange($event, dv) {
    if (dv.layout === 'list') {
      this.recsPerPage = AppConstants.listViewRecsPerPage;
      this.currentView = 'list';
    } else {
      this.recsPerPage = AppConstants.gridViewRecsPerPage;
      this.currentView = 'grid';
    }
  }

  ngOnInit() {
    this.getData();
  }

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  getData() {
    this.userService.getSiteHome().subscribe(
      (sitelist) => {
        this.sites = sitelist;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  onOpenHome(site: SiteHome) {
    sessionStorage.setItem('siteId', site.siteId.toString());
    sessionStorage.setItem('siteName', site.siteName);
    this.navService.notifyAlertsOfSiteChange();
    sessionStorage.setItem('userTypeId', site.userTypeId.toString());
    this.router.navigate(['/layout/home']);
  }
}
