import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TermsOfUseGuard {
  showModal = false;
  constructor(private router: Router) {}
  canActivate(): boolean {
    const isTosAccepted =
      sessionStorage.getItem('isTOSAccepted') === 'true';
    if (isTosAccepted === false) {
      this.router.navigate(['/layout/terms-of-use']);
      return false;
    }
    return true;
  }
}
