import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SiteHomeComponent } from './sitehome.component';
import { TrialModule } from '../trial/trial.module';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [CommonModule, SharedModule, TrialModule, RouterModule],
  declarations: [SiteHomeComponent],

  exports: [SiteHomeComponent],
})
export class SiteHomeModule {}
