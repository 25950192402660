import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../core/apiresponse';
import { ApiResponseResetPassword } from '../model/apiresponseresetpassword';

import { AppsettingsService } from '../../../core/appsettings.service';
import { ForgotPassword } from '../model/forgotpassword.model';
import { ResetPassword } from '../model/resetpassword.model';
import { UserSecurityQuestions } from '../model/usersecurityquestions.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  verifyEmailAddress(forgotPassword: ForgotPassword) {
    return this.http.post<ApiResponseResetPassword>(
      this.settings.baseUrl + 'api/forgotpassword',
      forgotPassword,
      this.httpOptions
    );
  }

  verifyEmailAddressSQ(forgotPassword: ForgotPassword) {
    return this.http.post<ApiResponseResetPassword>(
      this.settings.baseUrl + 'api/forgotpassword',
      forgotPassword,
      this.httpOptions
    );
  }

  verifyOTP(forgotPassword: ForgotPassword) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/forgotpassword/validateotp',
      forgotPassword,
      this.httpOptions
    );
  }

  verifySecQtnsAns(forgotPasswordSecqtn: UserSecurityQuestions) {
    return this.http.post<ApiResponseResetPassword>(
      this.settings.baseUrl + 'api/forgotpassword/validatesqa',
      forgotPasswordSecqtn,
      this.httpOptions
    );
  }

  // This one is for when the user changes their or site admin changes password
  resetPassword(resetPassword: ResetPassword) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/forgotpassworddetails',
      resetPassword,
      this.httpOptions
    );
  }
}
