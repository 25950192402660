import { FolderListItem } from './folderlist.model';

export enum BinderDocumentType {
  sitedocument = 1,
  operationsbinder = 2,
  regulatorybinder = 3,
  subjectdocument = 4,
}

export interface AddDocument {
  documentType: BinderDocumentType;
  inSubFolder?: boolean;
  siteId?: number;
  trialId?: number;
  subjectId?: number; // Only for subject documents
  folderId?: number;
  folderName?: string;
  folderList?: FolderListItem[];
}
